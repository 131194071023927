<template>
    <el-card class="evaluationProjecthHandle">
        <div class="first-title">基础信息</div>
        <el-form :model="submitForm" :rules="rules" ref="submitForm" label-width="100px" :disabled="$route.query.handleType === 'see'">
            <el-row>
                <el-col :span="8">
                    <el-form-item label="项目名称" prop="projectName">
                        <el-input class="w300px" clearable placeholder="请输入" v-model="submitForm.projectName"></el-input>
                    </el-form-item>
                    <el-form-item label="项目周期" prop="time">
                        <el-date-picker
                            v-model="submitForm.time"
                            class="w300px"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            @change="changeTime"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="创建账号" prop="userName">
                        <el-input class="w300px" disabled clearable placeholder="请输入" v-model="submitForm.userName"></el-input>
                    </el-form-item>
                    <el-form-item label="评估机构" prop="sysId">
                        <el-select class="w300px" v-model="submitForm.sysId" placeholder="请选择">
                            <el-option v-for="item in unitOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <EvaluationObjectTable @updateObject="updateObject" :editObjectList="editObjectList" :sceneId="sceneId" />
        <div class="btn" v-if="$route.query.handleType !== 'see'">
            <el-button @click="goBack">取消</el-button>
            <el-button type="primary" @click="doSubmit">确定</el-button>
        </div>
    </el-card>
</template>

<script>
import EvaluationObjectTable from './compontent/EvaluationObjectTable.vue'
import { evaluationProjectSave, evaluationProjectDetails, assessUnit } from '@/api/evaluationProject'
export default {
    // 评估项目新增/编辑
    name: 'evaluationProjecthHandle',
    components: {
        EvaluationObjectTable
    },
    watch: {
        $route: {
            handler(val) {
                if (val.query.id && val.query.handleType !== 'add') {
                    evaluationProjectDetails({ id: val.query.id }).then(res => {
                        for (const key in this.submitForm) {
                            this.submitForm[key] = res.data[key]
                        }
                        this.submitForm.time = [res.data.startTime, res.data.endTime]
                        this.submitForm.projectUserIds = res.data.userListVoList
                        this.editObjectList = res.data.objectVoList
                        this.submitForm.userName = res.data.createAccountName || JSON.parse(localStorage.getItem('cad_userInfo')).name
                        this.sceneId = res.data.applicationType
                    })
                }
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            sceneId: null, // 场景ID
            editObjectList: [], // 编辑评估对象列表
            unitOptions: [], // 评估机构下拉数据
            rules: {
                projectName: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
                userName: [{ required: true, message: '请获取创建账号', trigger: 'blur' }],
                time: [{ required: true, message: '请选择评估周期', trigger: 'change' }],
                sysId: [{ required: true, message: '请选择评估机构', trigger: 'change' }]
            },
            searchParams: {}, // 场景选择
            submitForm: {
                time: '',
                projectName: '', // 项目名称
                userName: JSON.parse(localStorage.getItem('cad_userInfo')).name, // 创建账号
                startTime: '',
                endTime: '',
                sysId: null, // 评估模板ID
                objectVoList: [] // 评估对象list
            },
            options: []
        }
    },
    mounted() {
        this.getUnit()
    },
    methods: {
        // 获取下级评估机构
        async getUnit() {
            const res = await assessUnit()
            this.unitOptions = res.data
        },
        // 获取时间周期
        changeTime(val) {
            this.submitForm.startTime = val ? val[0] : ''
            this.submitForm.endTime = val ? val[1] : ''
        },
        // 获取评估对象列表
        updateObject(list, searchParams) {
            this.submitForm.objectVoList = list
            this.searchParams = { ...searchParams }
            console.log(this.searchParams, 'updateObject')
        },
        // 检查表格数据
        checkTable() {
            let result = true
            if (this.submitForm.objectVoList.length === 0) {
                this.$message.info('请选择或导入评估对象')
                result = false
                return result
            }
            return result
        },
        // 获取提交参数
        getSubmitParams() {
            const data = Object.assign({ ...this.submitForm, ...this.searchParams })
            delete data.time
            delete data.userName
            if (this.$route.query.handleType === 'edit') {
                data.id = +this.$route.query.id
            }
            return data
        },
        // 提交表单
        doSubmit() {
            this.$refs.submitForm.validate(valid => {
                if (valid && this.checkTable()) {
                    const params = this.getSubmitParams()
                    console.log(params, 'params')
                    evaluationProjectSave({ ...params }).then(res => {
                        this.$message.success(res.data)
                        this.$router.push({ path: '/evaluationProject/index' })
                    })
                } else {
                    return false
                }
            })
        },
        // 返回
        goBack() {
            this.$router.push({ path: '/evaluationProject/index' })
        }
    }
}
</script>

<style lang="scss" scoped>
.evaluationProjecthHandle {
    .w300px {
        width: 300px;
    }
    .first-title {
        font-size: 18px;
        margin-bottom: 16px;
    }
}
</style>
